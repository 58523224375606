export const data = [
    {
        cellLineName: 'ALL-SIL',
        cellosaurusId: 'CVCL_1805',
        diseaseType: 'Childhood T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'CCLE, GDSC2, GDSC1, CTRPv2, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '676', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'CCRF-H-SB2',
        cellosaurusId: 'CVCL_1859',
        diseaseType: 'Childhood T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'DEL',
        cellosaurusId: 'CVCL_1170',
        diseaseType: 'Anaplastic large cell lymphoma',
        subtype: 'ALCL',
        publicDatasets: 'CCLE, GDSC2, CTRPv2, gCSI, GDSC1, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '785', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'DERL-2',
        cellosaurusId: 'CVCL_2016',
        diseaseType: 'Hepatosplenic T-cell lymphoma',
        subtype: 'gamma-delta',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'DERL-7',
        cellosaurusId: 'CVCL_2017',
        diseaseType: 'Hepatosplenic T-cell lymphoma',
        subtype: 'gamma-delta',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'DL-40',
        cellosaurusId: 'CVCL_2889',
        diseaseType: 'Anaplastic large cell lymphoma, ALK-negative',
        subtype: 'ALCL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'DND-41',
        cellosaurusId: 'CVCL_2022',
        diseaseType: 'Childhood T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'CCLE, GDSC2, GDSC1, CTRPv2, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '715', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'H9',
        cellosaurusId: 'CVCL_1240',
        diseaseType: 'Mycosis fungoides and Sezary syndrome',
        subtype: 'CTCL',
        publicDatasets: 'GDSC2, GDSC1, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '440', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'HH',
        cellosaurusId: 'CVCL_1280',
        diseaseType: 'Cutaneous T-cell non-Hodgkin lymphoma',
        subtype: 'CTCL',
        publicDatasets: 'CCLE, GDSC2, CTRPv2, gCSI, GDSC1, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '623', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'HUT-78',
        cellosaurusId: 'CVCL_0337',
        diseaseType: 'Mycosis fungoides and Sezary syndrome',
        subtype: 'CTCL',
        publicDatasets: 'CCLE, CTRPv2, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '438', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'Jurkat',
        cellosaurusId: 'CVCL_0065',
        diseaseType: 'Childhood T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'GDSC2, GDSC1, CTRPv2, TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '684', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'Jurkat E6.1',
        cellosaurusId: 'CVCL_0367',
        diseaseType: 'Childhood T acute lymphoblastic leukemia',
        subtype: '',
        publicDatasets: 'CCLE, GDSC2, gCSI',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '24', tcl38: '0' },
        numberOfDrugCombinations: { pharmacoDb: '0', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'Karpas 299',
        cellosaurusId: 'CVCL_1324',
        diseaseType: 'Anaplastic large cell lymphoma',
        subtype: 'ALCL',
        publicDatasets: 'CCLE, GDSC2, CTRPv2, gCSI, GDSC1, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '683', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'Karpas 384',
        cellosaurusId: 'CVCL_2541',
        diseaseType: 'Primary cutaneous T-cell non-Hodgkin lymphoma',
        subtype: 'gamma-delta',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'KHYG-1',
        cellosaurusId: 'CVCL_2976',
        diseaseType: 'Natural killer cell lymphoblastic leukemia/lymphoma',
        subtype: 'NK',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'KOPT-K1',
        cellosaurusId: 'CVCL_4965',
        diseaseType: 'Childhood T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'L-82',
        cellosaurusId: 'CVCL_2098',
        diseaseType: 'Anaplastic large cell lymphoma, ALK-positive',
        subtype: 'ALCL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'Loucy',
        cellosaurusId: 'CVCL_1380',
        diseaseType: 'Adult T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'CCLE, GDSC2, CTRPv2, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '433', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'Mac1',
        cellosaurusId: 'CVCL_H631',
        diseaseType: 'Primary cutaneous T-cell non-Hodgkin lymphoma',
        subtype: 'ALCL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: '',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'Mac2a',
        cellosaurusId: 'CVCL_H637',
        diseaseType: 'Anaplastic large cell lymphoma, ALK-negative',
        subtype: 'ALCL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: '',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'MOLT-4',
        cellosaurusId: 'CVCL_0013',
        diseaseType: 'Adult T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: '',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '4910', other: '5099' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'MOTN-1',
        cellosaurusId: 'CVCL_2127',
        diseaseType: 'T-cell large granular lymphocyte leukemia',
        subtype: 'T-LGLL',
        publicDatasets: 'CCLE, CTRPv2, TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '455', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'MTA',
        cellosaurusId: 'CVCL_3032',
        diseaseType: 'Natural killer cell lymphoblastic leukemia/lymphoma',
        subtype: 'NK',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'MyLa',
        cellosaurusId: '',
        diseaseType: '',
        subtype: 'CTCL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'NK-92',
        cellosaurusId: 'CVCL_2142',
        diseaseType: 'Natural killer cell lymphoblastic leukemia/lymphoma',
        subtype: 'NK',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'NKL',
        cellosaurusId: 'CVCL_0466',
        diseaseType: 'Natural killer cell lymphoblastic leukemia/lymphoma',
        subtype: 'NK',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'NK-YS',
        cellosaurusId: 'CVCL_8461',
        diseaseType: 'Nasal type extranodal NK/T-cell lymphoma',
        subtype: 'NK',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'OCI-Ly13.2',
        cellosaurusId: 'CVCL_8797',
        diseaseType: 'Diffuse large B-cell lymphoma',
        subtype: 'ALCL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'Peer',
        cellosaurusId: 'CVCL_1913',
        diseaseType: 'Childhood T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'CCLE, CTRPv2, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '450', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'SeAx',
        cellosaurusId: 'CVCL_5363',
        diseaseType: 'Sezary syndrome',
        subtype: 'CTCL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'SMZ-1',
        cellosaurusId: 'CVCL_RL84',
        diseaseType: 'Systemic lupus erythematosus,T-cell non-Hodgkin lymphoma',
        subtype: 'PTCL-NOS',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'SNK-6',
        cellosaurusId: 'CVCL_A673',
        diseaseType: 'Nasal type extranodal NK/T-cell lymphoma',
        subtype: 'NK',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'SU-DHL-1',
        cellosaurusId: 'CVCL_0538',
        diseaseType: 'Anaplastic large cell lymphoma',
        subtype: 'ALCL',
        publicDatasets: 'CCLE, GDSC2, CTRPv2, gCSI, GDSC1, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '623', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'SUP-M2',
        cellosaurusId: 'CVCL_2209',
        diseaseType: 'Anaplastic large cell lymphoma',
        subtype: 'ALCL',
        publicDatasets: 'CCLE, GDSC2, CTRPv2, GDSC1, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '691', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'SUP-T11',
        cellosaurusId: 'CVCL_2210',
        diseaseType: 'Adult T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'CCLE, CTRPv2, TCL38',
        omicsAvailability: 'rnaseq, microarray, cnv, mutation, acgh, atacseq',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '450', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    },
    {
        cellLineName: 'SUP-T13',
        cellosaurusId: 'CVCL_9973',
        diseaseType: 'Childhood T acute lymphoblastic leukemia',
        subtype: 'T-ALL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'TLBR-1',
        cellosaurusId: 'CVCL_L177',
        diseaseType: 'Anaplastic large cell lymphoma, ALK-negative',
        subtype: 'ALCL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'TLBR-2',
        cellosaurusId: 'CVCL_A1EY',
        diseaseType: 'Anaplastic large cell lymphoma, ALK-negative',
        subtype: 'ALCL',
        publicDatasets: 'TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '0', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'No'
    },
    {
        cellLineName: 'YT',
        cellosaurusId: 'CVCL_1797',
        diseaseType: 'Natural killer cell lymphoblastic leukemia/lymphoma',
        subtype: 'NK',
        publicDatasets: 'GDSC2, GDSC1, TCL38',
        omicsAvailability: 'rnaseq, acgh, atacseq, cnv',
        singleAgentTesting: 'Yes',
        numberOfSingleAgents: { pharmacoDb: '380', tcl38: '173' },
        numberOfDrugCombinations: { pharmacoDb: '17', tcl38: '0', other: '0' },
        datasetOnOrcestra: 'Yes'
    }
];
